import { Flex, Row, Space } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_STATUS } from "~/@types/notification";
import icons from "~/assets/icons";
import { CHANNELS } from "~/constants/websocket";
import { websocket } from "~/core/websocket";
import useResponsive from "~/hooks/useResponsive";
import { selectUserInfo, useAppDispatch, useAppSelector } from "~/redux";
import {
  setNotification,
  unshiftNotification,
} from "~/redux/slices/notificationSlice";
import { getNotifications } from "~/services/notification";
import FButton from "../FButton";
import FModal from "../FModal";
import "./styles.scss";
import DepositModal from "../DepositModal";
import { PAYMENT_URL_TYPE } from "~/@types";
import { LINKS } from "~/configs";

type TWebsocketProviderProps = {
  children: React.ReactNode;
};

const NotificationProvider = ({ children }: TWebsocketProviderProps) => {
  const user = useAppSelector(selectUserInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpenAuctionEnd, setIsOpenAuctionEnded] = useState(false);
  const [isOpenAuctionWinning, setIsOpenAuctionWinning] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [auctionId, setAuctionId] = useState("");
  const [tourId, setTourId] = useState("");
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (user.id) {
      (async () => {
        websocket.onEvent(CHANNELS.NOTIFICATION, (data: any) => {
          try {
            const { notificationCode, userId, data: metadata } = data;
            if (user.id !== userId) return;
            dispatch(unshiftNotification(notificationCode));

            if (
              notificationCode === NOTIFICATION_STATUS.USER_AUCTION_SUCCESSFULLY
            ) {
              setAuctionId(metadata.auctionId);
              setTourId(metadata.tourId);
              setIsOpenAuctionWinning(true);
              return;
            }

            if (notificationCode === NOTIFICATION_STATUS.USER_AUCTION_FAILED) {
              setAuctionId(metadata.auctionId);
              setTourId(metadata.tourId);
              setIsOpenAuctionEnded(true);
            }
          } catch (error) {
            console.error("unshiftNotification", error);
          }
        });
        const notificationsData = await getNotifications({ userId: user.id });
        dispatch(setNotification(notificationsData));
      })();
    }

    return () => {
      websocket.offEvent(CHANNELS.NOTIFICATION);
    };
  }, [user.id]);

  const handlePayment = () => {
    setIsOpenAuctionWinning(false);
    setOpenDepositModal(true);
  };

  const handleViewAuctionDetail = () => {
    setIsOpenAuctionWinning(false);
    setIsOpenAuctionEnded(false);
    navigate(`${LINKS.auction}/${tourId}/${auctionId}`);
  };
  return (
    <React.Fragment>
      {children}
      <FModal
        title={t("auction_ended")}
        open={isOpenAuctionEnd}
        rootClassName="auction-modal ended-auction"
        onCancel={() => setIsOpenAuctionEnded(false)}
        onClose={() => setIsOpenAuctionEnded(false)}
      >
        <Flex justify="center" className="content">
          <Space
            direction="vertical"
            size={isMobile ? 30 : 73}
            align="center"
            style={{ width: 500 }}
          >
            <img width={100} height={100} src={icons.sad} alt="warning" />
            <p className="text-center">
              {t(
                "your_bid_was_not_successful_thank_you_for_participating_stay_tuned_for_future_auctions"
              )}
            </p>
          </Space>
        </Flex>
        <Row
          justify="center"
          gutter={[15, 15]}
          className="btn-confirms"
          style={{ marginTop: isMobile ? 10 : 50 }}
        >
          <FButton
            style={{ width: 357, height: 55 }}
            className="f-bg-none"
            onClick={handleViewAuctionDetail}
          >
            {t("view_aution_detail")}
          </FButton>
        </Row>
      </FModal>
      <FModal
        title={t("auction_successful")}
        open={isOpenAuctionWinning}
        rootClassName="auction-modal winning-auction"
        onCancel={() => setIsOpenAuctionWinning(false)}
        onClose={() => setIsOpenAuctionWinning(false)}
      >
        <Flex justify="center" className="content">
          <Space
            direction="vertical"
            size={isMobile ? 30 : 47}
            align="center"
            style={{ width: 500 }}
          >
            <img width={100} height={100} src={icons.love_face} alt="warning" />
            <p>
              <b>{t("congratulations_your_bid_was_successful")}</b>
            </p>
            <p
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: t(
                  "please_proceed_with_payment_within_the_next_b24_hoursb_to_secure_your_tour_note_that_you_do_not_have_the_right_to_purchase_after_this_period"
                ),
              }}
            ></p>
          </Space>
        </Flex>
        <Row
          justify="center"
          className="btn-confirms"
          style={{ marginTop: isMobile ? 10 : 50 }}
        >
          <FButton
            style={{ width: 357, height: 55 }}
            className="f-bg-none"
            onClick={handleViewAuctionDetail}
          >
            {t("view_aution_detail")}
          </FButton>
          <FButton
            style={{ width: 357, height: 55 }}
            className="primary-1"
            onClick={handlePayment}
          >
            {t("payment")}
          </FButton>
        </Row>
      </FModal>
      <DepositModal
        openModal={openDepositModal}
        handleCloseModal={() => {
          setOpenDepositModal(false);
          setIsOpenAuctionWinning(true);
        }}
        auction={{ auctionId }}
        type={PAYMENT_URL_TYPE.WIN_AUCTION}
      />
    </React.Fragment>
  );
};

export default NotificationProvider;
