import { Button, Result } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "~/components/Loading";
import { LINKS } from "~/configs";
import { APLUS_PAYMENT_STATUS } from "~/constants/payment";
import { validatePaymentResult } from "~/services/payment";
import "./styles.scss";

const PaymentResult = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [result, setResult] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [nextActionUrl, setNextActionUrl] = useState<string | null>(null);

  const getResult = (status: string) => {
    switch (status) {
      case APLUS_PAYMENT_STATUS.COMPLETED:
        return {
          status: "success",
          title: t("payment_success"),
          subTitle: t("payment_success_message"),
        };
      case APLUS_PAYMENT_STATUS.FAILED:
        return {
          status: "error",
          title: t("payment_failed"),
          subTitle: t("payment_failed_message"),
        };
      case APLUS_PAYMENT_STATUS.CANCELED:
        return {
          status: "warning",
          title: t("payment_canceled"),
          subTitle: t("payment_canceled_message"),
        };
      default:
        return {
          status: "info",
          title: t("payment_pending"),
          subTitle: t("payment_pending_message"),
        };
    }
  };
  useEffect(() => {
    // Get all search params
    const params = Object.fromEntries(searchParams.entries());
    validatePaymentResult(params).then((res: any) => {
      if (res.data.isValid) {
        setIsValid(true);
        setResult(getResult(params.status));
        setNextActionUrl(params.nextActionUrl || null);
      } else {
        setResult({
          status: "error",
          title: t("payment_invalid"),
          subTitle: t("payment_invalid_message"),
        });
      }
    });
    setLoading(false);
  }, [searchParams]);

  const handleBackHome = () => {
    navigate(LINKS.home);
  };

  const handleViewAuction = () => {
    if (nextActionUrl) {
      window.location.href = nextActionUrl;
    }
  };

  if (loading) {
    return <Loading isLoading={loading} />;
  }

  return (
    <div className="payment-result-container">
      <Result
        status={result.status}
        title={result.title}
        subTitle={result.subTitle}
        extra={[
          <Button type="primary" key="home" onClick={handleBackHome}>
            {t("back_to_home")}
          </Button>,
          ...[
            isValid && !!nextActionUrl
              ? [
                  <Button key="auction" onClick={handleViewAuction}>
                    {t("view_auction")}
                  </Button>,
                ]
              : [],
          ],
        ]}
      />
    </div>
  );
};

export default PaymentResult;
