export type TAuctionStatus = "OPEN" | "ENDED";

export type TFilters = {
  limit?: string | number;
  page?: string | number;
  categoryId?: string[];
  numberOfAdults?: string | number;
  numOfChild?: string | number;
  locationId?: string[];
  type?: "auction" | "buy-now";
  dateFrom?: string;
  minPrice?: string;
  maxPrice?: string;
  status?: "upcoming" | "open";
};

export type IFiltersBlog = {
  limit?: string | number;
  page?: string | number;
  categoryId?: string | number;
};

export enum PAYMENT_URL_TYPE {
  DEPOSIT = "DEPOSIT",
  BUY_NOW = "BUY_NOW",
  WIN_AUCTION = "WIN_AUCTION",
}
