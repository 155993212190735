import { Button, Flex, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { clsx } from "clsx";
import { getPaymentUrl } from "~/services/payment";
import Price from "../Price";
import "./styles.scss";
import { PAYMENT_URL_TYPE } from "~/@types";
import { LoadingOutlined } from "@ant-design/icons";

const DepositModal = ({
  openModal,
  handleCloseModal,
  auction,
  type,
}: {
  openModal: boolean;
  handleCloseModal: () => void;
  auction: any;
  type: PAYMENT_URL_TYPE;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [content, setContent] = useState({ title: "", message: "", note: "" });
  const { id, auctionId } = auction || {};

  const getContent = () => {
    const c = { title: "", message: "", note: "" };
    switch (type) {
      case PAYMENT_URL_TYPE.DEPOSIT:
        c.title = t("deposit_required");
        c.message = t("deposit_message");
        c.note = t("deposit_note");
        break;
      case PAYMENT_URL_TYPE.BUY_NOW:
        c.title = t("buy_now");
        c.message = t("buy_now_message");
        c.note = t("buy_now_note");
        break;
      case PAYMENT_URL_TYPE.WIN_AUCTION:
        c.title = t("complete_auction");
        c.message = t("win_auction_message");
        c.note = t("win_auction_note");
        break;
      default:
        break;
    }
    setContent(c);
  };

  const handleOk = () => {
    window.location.href = paymentUrl;
  };

  const init = () => {
    setLoading(true);
    const nextActionUrl = window.location.href;
    getPaymentUrl(id || auctionId, type, nextActionUrl)
      .then((res: any) => {
        setPaymentUrl(res.data.paymentUrl);
        setPaymentAmount(res.data.amount);
      })
      .catch(() => {
        handleCloseModal();
      });
    getContent();
    setLoading(false);
  };

  useEffect(() => {
    if (openModal) {
      init();
    }
  }, [openModal]);

  return (
    <Modal
      title={content.title}
      open={openModal}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" disabled={loading} onClick={handleCloseModal}>
          {t("cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={loading}
          onClick={handleOk}
        >
          {t("proceed_to_payment")}
        </Button>,
      ]}
    >
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
      ) : (
        <div className="deposit-content">
          <p className="text-message">{content.message}</p>
          <Flex justify="space-between" align="center" className="item-price">
            <p className="text-deposit-amount text-orange-1">
              {t("amount_of_money")}
            </p>
            <Price
              price={paymentAmount}
              priceClassName={clsx({
                "text-orange-1": true,
              })}
            />
          </Flex>
          <p className="text-note">{content.note}</p>
        </div>
      )}
    </Modal>
  );
};

export default DepositModal;
