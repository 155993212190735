import { Flex } from "antd";
import clsx from "clsx";
import { formatMoney } from "~/utils/number";
import "./styles.scss";

type TPriceProps = {
  priceClassName?: string;
  price: number | string;
  style?: React.CSSProperties;
  isBold?: boolean;
};

const Price = ({ priceClassName, price, style = {} }: TPriceProps) => {
  return (
    <Flex className="bold-text bid-pricing" gap={8} align="center">
      <span className="unit">USD</span>
      <span
        className={clsx("h3 mb-0", priceClassName)}
        style={{
          fontSize: 40,
          lineHeight: "44px",
          letterSpacing: "2px",
          ...style,
        }}
      >
        {formatMoney(price)}
      </span>
    </Flex>
  );
};

export default Price;
