import { message } from "antd";
import { TBaseResponse } from "~/types";
import AxiosInstance from "./networking";

export async function getPaymentUrl(
  auctionId: string,
  type: string,
  nextActionUrl?: string
) {
  try {
    const response = await AxiosInstance.get<TBaseResponse<{}, {}>>(
      `payment/get-payment-url?auctionId=${auctionId}&type=${type}${
        !!nextActionUrl ? `&nextActionUrl=${nextActionUrl}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    message.error("Can not get payment url");
  }
}

export async function validatePaymentResult(params: any) {
  try {
    const response = await AxiosInstance({
      method: "GET",
      url: `payment/validate-payment-result`,
      params,
    });
    return response.data;
  } catch (error) {
    message.error("Can not validate payment result");
  }
}
