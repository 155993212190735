import { Flex, message } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import icons from "~/assets/icons";
import DownUp from "~/components/DownUp";
import FButton from "~/components/FButton";
import Price from "~/components/Price";
import DepositModal from "~/components/DepositModal";
import { LINKS } from "~/configs";
import { websocket } from "~/core/websocket";
import useResponsive from "~/hooks/useResponsive";
import { selectUserInfo, useAppSelector } from "~/redux";
import {
  addAuctionWishlist,
  checkRegisterAuction,
  registerAuctionForBidding,
  checkUserDeposit,
} from "~/services";
import { formatDate } from "~/utils";
import {
  AUCTION_STATUS,
  EXPIRED,
  NOT_STARED,
  countdownFormADay,
  getBiddersToShow,
  hiddenName,
  isMaxShowBidder,
} from "~/utils/utils";
import "./styles.scss";
import { PAYMENT_URL_TYPE } from "~/@types";

const AuctionBidCard = ({ isShowAction = true, auctionDetails }: any) => {
  const { auction, bidders: biddersFromServer } = auctionDetails;
  const {
    currentPrice,
    endTime: timeEnd,
    startTime: timeStart,
    startedPrice: startPrice,
    isFavourited,
    id,
    // status,
    winnerId,
    isCompleted,
    isBuyNow,
  } = auction;
  const navigate = useNavigate();
  const params = useParams();
  const [bidders, setBidders] = useState<any>(biddersFromServer);
  const { isMobile } = useResponsive();
  const [currentBid, setCurrentBid] = useState(currentPrice);
  const [timeLeft, setTimeLeft] = useState(NOT_STARED);
  const [isCollapsed, setIsCollapse] = useState(false);
  const [status, setStatus] = useState<number>(AUCTION_STATUS.UPCOMING);
  const [isRegisted, setIsRegisted] = useState(false);
  const [openDepositPopup, setOpenDepositPopup] = useState(false);
  const [paymentUrlType, setPaymentUrlType] = useState(
    PAYMENT_URL_TYPE.DEPOSIT
  );

  const isBidded = false;
  const uniqueBidders = _.uniqBy(bidders, "username");
  const [isFavorite, setIsFavorite] = useState(isFavourited || false);
  const { t } = useTranslation();
  const [, setOpen] = useState(false);
  const userInfo = useAppSelector(selectUserInfo);
  const isLogin = !!userInfo.id;

  useEffect(() => {
    if (status === AUCTION_STATUS.UPCOMING && userInfo.id) {
      checkRegisterAuction(userInfo.id).then((response: any) => {
        setIsRegisted(response.data?.isRegistered);
      });
    }
  }, [status, userInfo.id]);

  useEffect(() => {
    if (timeLeft === NOT_STARED) {
      setStatus(AUCTION_STATUS.UPCOMING);
    } else if (timeLeft === EXPIRED) {
      setStatus(AUCTION_STATUS.ENDED);
    } else {
      setStatus(AUCTION_STATUS.OPEN);
    }
  }, [timeLeft]);

  async function handleClickWishlist() {
    try {
      await addAuctionWishlist(`${id}`);
      setIsFavorite(!isFavorite);
      message.success(t("successful"));
    } catch (error) {
      message.error(t("failed"));
    }
  }

  useEffect(() => {
    const timer = countdownFormADay(timeEnd, timeStart, setTimeLeft);

    return () => {
      clearInterval(timer);
    };
  }, [timeEnd, timeStart]);

  useEffect(() => {
    if (timeLeft === EXPIRED && isLogin) {
      setOpen(true);
    }
  }, [timeLeft, isLogin]);

  useEffect(() => {
    if (id) {
      function onFooEvent(value: any) {
        if (value) {
          try {
            const { bidPrice, user } = JSON.parse(value);
            const myBidder = {
              bidderName: user.email,
              bidTime: new Date(),
              bidAmount: bidPrice,
            };
            setBidders((prev: any) => [myBidder].concat(prev));
            setCurrentBid(bidPrice);
          } catch (error) {
            message.error("Socket was receive some errors");
          }
        }
      }

      websocket.onEvent(id, onFooEvent);

      return () => {
        if (id) {
          websocket.offEvent(id);
        }
      };
    }
  }, [id]);

  const isSomeOneWinning = bidders.some(({ isWinning }: any) => isWinning);
  const winner = bidders.find(({ isWinning }: any) => isWinning);

  const handleCollapse = () => {
    if (isMobile) {
      setIsCollapse((prev) => !prev);
    }
  };

  const buttonStatus = useMemo(() => {
    const className = clsx("button-open", {
      "primary-3": status === AUCTION_STATUS.OPEN,
      "disabled-1": status === AUCTION_STATUS.ENDED,
      "primary-4": status === AUCTION_STATUS.UPCOMING,
    });

    let text = "";

    if (status === AUCTION_STATUS.ENDED) {
      text = "end";
    }

    if (status === AUCTION_STATUS.OPEN) {
      text = "open";
    }

    if (status === AUCTION_STATUS.UPCOMING) {
      text = "upcoming";
    }

    if (text) {
      return (
        <FButton style={{ fontSize: 12 }} className={className}>
          {t(text as any)}
        </FButton>
      );
    }

    return null;
  }, [status]);

  const handleRegisterAuctionBidding = () => {
    registerAuctionForBidding(id);
    setIsRegisted(true);
  };

  const handleCheckUserDeposit = () => {
    checkUserDeposit(id).then((res: any) => {
      if (res?.data?.isDeposited) {
        navigate(`/auction/${params.tourId}/${params.auctionId}/join-bid`);
      } else {
        setPaymentUrlType(PAYMENT_URL_TYPE.DEPOSIT);
        setOpenDepositPopup(true);
      }
    });
  };

  const action = useMemo(() => {
    if (!isLogin || !isShowAction) return null;

    if (timeLeft === NOT_STARED) {
      if (isRegisted) return null;

      return (
        <Flex vertical gap={16}>
          <FButton
            className="primary-1 button-action-bid"
            onClick={handleRegisterAuctionBidding}
          >
            {t("register_for_bidding")}
          </FButton>
          <FButton
            className="f-bg-none button-action-bid"
            onClick={handleClickWishlist}
          >
            {t("add_to_wishlist")}
          </FButton>
        </Flex>
      );
    }

    if (timeLeft !== EXPIRED) {
      return (
        <Flex vertical gap={16}>
          {isBidded ? (
            <FButton
              className="primary-1 button-action-bid"
              onClick={() =>
                navigate(
                  `/auction/${params.tourId}/${params.auctionId}/payment`
                )
              }
            >
              {t("payment")}
            </FButton>
          ) : (
            <FButton
              className="primary-1 button-action-bid"
              onClick={handleCheckUserDeposit}
            >
              {t("join_bid")}
            </FButton>
          )}
          <FButton
            className="f-bg-none button-action-bid"
            onClick={handleClickWishlist}
          >
            {t("add_to_wishlist")}
          </FButton>
          {!isSomeOneWinning && (
            <FButton
              onClick={() => {
                setPaymentUrlType(PAYMENT_URL_TYPE.BUY_NOW);
                setOpenDepositPopup(true);
              }}
              className="primary-4 button-action-bid"
            >
              {t("buy_now")}
            </FButton>
          )}
        </Flex>
      );
    }

    if (
      status === AUCTION_STATUS.ENDED &&
      userInfo.id === winnerId &&
      !isCompleted &&
      !isBuyNow
    ) {
      return (
        <Flex vertical gap={16}>
          <FButton
            onClick={() => {
              setPaymentUrlType(PAYMENT_URL_TYPE.WIN_AUCTION);
              setOpenDepositPopup(true);
            }}
            className="primary-1 button-action-bid"
          >
            {t("complete_auction")}
          </FButton>
        </Flex>
      );
    }

    return null;
  }, [
    isSomeOneWinning,
    isLogin,
    params,
    isShowAction,
    handleClickWishlist,
    timeLeft,
    handleRegisterAuctionBidding,
    isRegisted,
    userInfo,
    isBuyNow,
    isCompleted,
    winnerId,
  ]);

  return (
    <>
      <div
        className={clsx("auction-bid-card", {
          collapsed: isCollapsed,
          mobile: isMobile,
        })}
      >
        <Flex
          justify="space-between"
          align="center"
          onClick={handleCollapse}
          className="auction-status"
        >
          <span className="h5 mb-0">{t("auction")}</span>
          {isMobile ? <DownUp isDown={isCollapsed} /> : buttonStatus}
        </Flex>
        {isMobile && !isCollapsed ? (
          <div className="mt-8">{buttonStatus}</div>
        ) : null}
        <Flex justify="space-between" align="center" className="item-price">
          <span>{t("original_price")}</span>
          <Price
            priceClassName="text-strikethrough"
            style={{ fontSize: 24 }}
            price={50.5}
          />
        </Flex>
        <Flex justify="space-between" align="center" className="item-price">
          <span>{t("starting")}</span>
          <Price price={startPrice} priceClassName="text-primary-1" />
        </Flex>
        <Flex
          justify="space-between"
          align="center"
          className={clsx("current-bid item-price", {
            "text-success": isSomeOneWinning,
            "text-orange-1": !isSomeOneWinning,
          })}
        >
          <span>{isSomeOneWinning ? t("winning_bid") : t("current_bid")}</span>
          <Price
            price={winner?.price || currentBid}
            priceClassName={clsx({
              "text-success": isSomeOneWinning,
              "text-orange-1": !isSomeOneWinning,
            })}
          />
        </Flex>
        <div className="item-price bid-block">
          {isSomeOneWinning && (
            <div className="mt-8 mb-8 text-success">
              {t("your_bid_was_successful")}
            </div>
          )}
          <div className="bids-list">
            <Flex
              justify="space-between"
              align="center"
              className="current-bid item-in-current-bid"
            >
              <FButton
                className="button-open primary-4"
                icon={<img src={icons.hammers_bid} />}
              >
                {bidders.length} {t("bids")}
              </FButton>
              <div className="text-primary-1">
                <a>{t("bidders")}</a>: {uniqueBidders.length}
              </div>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              className="current-bid text-primary-1 item-in-current-bid mb-8"
            >
              <a>{t("bidder")}</a>
              <a>{t("bid_amount")}</a>
            </Flex>
            <div className="all-bidders-list">
              {getBiddersToShow(bidders).map(
                (
                  { bidderName, bidTime, bidAmount, isWinning }: any,
                  index: number
                ) => {
                  const isMax = isSomeOneWinning ? false : Boolean(index === 0);

                  return (
                    <div
                      key={index}
                      className={clsx("user-info-bid-outer", {
                        active: isWinning || isMax,
                      })}
                    >
                      <Flex
                        justify="space-between"
                        className="item-in-current-bid user-info-bid-inner"
                        wrap
                      >
                        <Flex flex={"1"}>
                          <Flex vertical>
                            <span
                              className={clsx(
                                "username-bided text-ellipsis mb-8",
                                {
                                  "text-success": isWinning,
                                  "bold-text": isWinning || isMax,
                                }
                              )}
                            >
                              {hiddenName(bidderName)}
                            </span>
                            <span className="date-bid">
                              {formatDate(bidTime, "DD MMM YYYY hh:mm:ss")}
                            </span>
                          </Flex>
                        </Flex>
                        <Flex
                          className={clsx("pricing", {
                            "text-success": isWinning,
                            "bold-text": isWinning || isMax,
                            "text-orange-1": !isWinning,
                          })}
                        >
                          ${bidAmount}
                        </Flex>
                      </Flex>
                    </div>
                  );
                }
              )}
            </div>
            {isMaxShowBidder(bidders) ? (
              <Link to={LINKS.auctionBidder}>
                <Flex justify="center" className="show-all text-primary-1 mt-8">
                  Show all
                </Flex>
              </Link>
            ) : null}
          </div>
        </div>
        <Flex justify="space-between" align="center" className="item-price">
          <span>{t("time_left")}</span>
          <span className="bold-text">
            {timeLeft === EXPIRED ? t("expired") : timeLeft}
          </span>
        </Flex>
        <Flex
          justify="space-between"
          align="center"
          style={{ borderBottom: "none" }}
          className="item-price"
        >
          <span>{t("duration")}</span>
          <div style={{ textAlign: "right" }} className="bold-text">
            {dayjs(timeEnd).diff(dayjs(timeStart), "day")} {t("day")}
          </div>
        </Flex>
        {action}
      </div>
      <DepositModal
        openModal={openDepositPopup}
        handleCloseModal={() => setOpenDepositPopup(false)}
        auction={auction}
        type={paymentUrlType}
      />
    </>
  );
};

export default AuctionBidCard;
