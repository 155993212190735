import { Badge, Dropdown, Flex, Space, Avatar } from "antd";
import { Link } from "react-router-dom";

import strings from "~/assets/strings";
import { NotificationsAll } from "~/components";
import NavLinkIsDesktop from "~/components/NavLink/NavLinkIsDesktop";
import { LINKS } from "~/configs";
import { selectUserInfo, useAppSelector } from "~/redux";
import { selectIsHasNewNoti } from "~/redux/slices/notificationSlice";
import "../styles.scss";
import avatar from "/images/profile.png";

const UserInfo = () => {
  const { lastName, firstName } = useAppSelector(selectUserInfo);
  const isHasNewNoti = useAppSelector(selectIsHasNewNoti);

  return (
    <Flex
      gap={20}
      align="baseline"
      justify="flex-end"
      className="wrapper-user-info"
    >
      <Link to={LINKS.myWishList}>
        <Space>
          <i
            className="fa-solid fa-heart fa-xl"
            style={{ color: "#ffffff" }}
          />
          <span>{strings().wishlist}</span>
        </Space>
      </Link>
      <Dropdown trigger={["click"]}
        dropdownRender={() => <NotificationsAll />} placement="bottomRight">
        <Badge dot={isHasNewNoti}>
          <i
            className="fa-solid fa-bell fa-xl"
            style={{ color: "#ffffff" }}
          />
        </Badge>
      </Dropdown>
      <Dropdown
        trigger={["click"]}
        menu={{ items: [{ label: <NavLinkIsDesktop />, key: 1 }] }}
        placement="bottomRight"
        rootClassName="dropdown-menu-header"
      >
        <Space align="baseline">
          <Avatar src={avatar} shape="circle" size="default" />
          <span>{`${firstName} ${lastName}`}</span>
          <i className="fa-regular fa-chevron-down"></i>
        </Space>
      </Dropdown>
    </Flex>
  );
};

export default UserInfo;
